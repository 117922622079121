import { Chip, Stack, Divider, Box, Grid, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import google_play_badge from './google_play_badge.png';
import Text from 'react';
import logo from './logo.svg';

// use to remove the underlining 
const ItemLink = styled(Link)({
  textDecoration: "none"
})

const IngrediantItem = ({text, filled, onClick}) =>
    <Chip label={text} onClick={()=>{onClick()}} color="primary" variant={filled?"filled":"outlined"} />
const FilterItem = ({text, filled, onClick}) =>
    <Button size="large" sx={ { borderRadius: 28 } } onClick={()=>{onClick()}} color="primary" variant={filled?"contained":"outlined"} >{text}</Button>

const AppBoxItem = ({title, header, description, videoSrc, appLink}) => {
    return (<Box onClick={()=>{}}
                sx={[{
                    '&:hover': {
                    /*backgroundColor: "#0b0b0b",
                    boxShadow: 15*/
                    },
                },{
                    width:"90vw",
                    height: "500px",
                    margin: "2vh",
                    display: "flex",
                    borderRadius:"10px",
                    justifyContent: "center",
                    flexDirection: 'column',
                    alignItems: "center",
                    backgroundColor: "#1D1D1D",
                    boxShadow: 3
                }]}>

                <img src={header} style={{objectFit: "contain", height:"200px", width:"100%"}} alt="Logo" />

                <div style={{ color: "#ffffff", margin:"20px" }}>
                    <b style={{fontSize: "32px"}}>{title}</b>
                    <br/>
                    <br/>
                    <i style={{fontSize: "18px"}}>{description}</i>
                </div>
                
                <a href={appLink}>
                    <img src={google_play_badge} style={{objectFit: "contain", height:"125px", width:"200px"}} alt="Logo" />
                </a>
            </Box>)
}
//<iframe style={{width:"95vw", height:"75vh" }}
                //        src={videoSrc} title="YouTube video player" frameborder="0" wmode = "transparent" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                //</iframe>
const AboutBoxItem = ({ico, title, description, onClick}) => {
    return (<Box
                onClick={()=>{onClick()}}
                sx={[{
                    '&:hover': {
                    backgroundColor: "#0b0b0b",
                    boxShadow: 15
                    },
                },{
                    width: "75%",
                    height: "200px",
                    margin: "2vh",
                    display: "flex",
                    borderRadius:"10px",
                    justifyContent: "center",
                    flexDirection: 'column',
                    alignItems: "center",
                    backgroundColor: "#1D1D1D",
                    boxShadow: 3
                }]}>

                <div style={{ 
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: 'column',
                    alignItems: "center", height:"50%", width:"90%", color: "#ffffff"}}>
                        <br/>
                        <img src={ico} style={{objectFit: "contain", height:"100px", width:"100px"}} alt="Logo" />
                        <br/>
                        <b style={{fontSize: "32px"}}>{title}</b>
                        <br/>
                        <i style={{fontSize: "18px", paddingBottom: "75px"}}>{description}</i>
                </div>

                </Box>)
}
export {
    AboutBoxItem,
    AppBoxItem
}