import { Box } from '@mui/material';
import cacerescreations_full_icon from '../cacerescreations_full_icon.png';
import tiktok_logo from '../tiktok-logo.png';
import yt_logo_rgb_dark from '../yt_logo_rgb_dark.png';
import '../App.css';

export default function Header() {

    return (<Box
              id="header"
              className={"header"}
              sx={{
                  width:"100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: 'column',
                  alignItems: "center",
                  backgroundImage: "linear-gradient(rgba(70,70,70,1) 0%, rgba(29, 29, 29, 1) 100%)",
                  height:"100vh",
                  boxShadow: 3
              }}
          >

        <img src={cacerescreations_full_icon} style={{objectFit: "contain", height:"512px", width:"40%"}} alt="Logo" />
           
        <Box
            id="footer"
            sx={{
                width:"100%",
                display: "flex",
                justifyContent: "center",
                flexDirection: 'row',
                alignItems: "center",
                height:"75px",
            }}>
            <a href="https://www.tiktok.com/@caceres_creations">
                <img src={tiktok_logo} style={{objectFit: "contain", marginTop:"10px", marginLeft:"10px",
                marginBottom:"10px", marginRight:"3px", height:"50px", width:"50px"}} alt="Logo" />
            </a>
            <a href="https://www.youtube.com/channel/UCpvmakEwm7_gE5h3WrtI0pw">
                <img src={yt_logo_rgb_dark} style={{objectFit: "contain", marginTop:"10px", marginLeft:"10px",
                marginBottom:"10px", marginRight:"3px", height:"75px", width:"75px"}} alt="Logo" />
            </a>
        </Box>

        {/*<Button variant="outlined"
                        onClick={ () => {  }}
                        theme={createTheme({
                            palette: {
                                mode: "dark",
                                primary: {
                                    main: '#fff',
                                },
                            }
                            })}
                        id="headerFootnoteText"
                        className="headerFootnoteAnim"
                        style={{color:"#ffffff", margin:"10px", 
                        position: "absolute",
                        bottom: "15px", backgroundColor:"#5555ff"}}><i>Scroll to Explore</i></Button>*/}
        
    </Box>)
}