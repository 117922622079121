import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import bubbles_ic_launcher from './bubbles_ic_launcher.png'

export default function BubblesHomePage() {

    
    document.title = "Bubbles Home Page"

    return  <div>

        {/* body container */}
        <div style={{ 
                display:"flex",
                justifyContent: "center",
                flexDirection: 'column',
                alignItems: "center",
                padding:"25px",
                marginTop:"1px"}}>

            {/* policy content box */}
            <Box
                sx={{
                    width: "75%",
                    display: "flex",
                    borderRadius:"10px",
                    justifyContent: "center",
                    flexDirection: 'column',
                    alignItems: "center",
                    backgroundColor: "#1D1D1D",
                    color: "#ffffff",
                    boxShadow: 3
                }}>

                <img style={{height:"200px",width:"200px"}}src={bubbles_ic_launcher}></img>

                <div style={{ 
                    display: "flex",
                    justifyContent: "start",
                    flexDirection: 'column',
                    alignItems: "center", height:"50%", width:"90%"}}>

                    <b style={{fontSize: "32px"}}>Google OAuth2 in Bubbles Home Page</b>

                </div>

                <div style={{ 
                    display: "flex",
                    justifyContent: "start",
                    flexDirection: 'column',
                    alignItems: "start", height:"50%", width:"90%"}}>
                    
                    <p>
                        <h2>About:</h2>
                        <p>
                            Bubbles is designed to be a better way to view and manage all of your Youtube subscriptions. In order to use it, you must sign in with a Google account.
                        </p>
                        <p>
                            If you sign in, Google shares your account info (username, profile icon, email, etc.) with me. Most of info will be kept in the app on your device. The only exception is your Google accounts Youtube id. Which is both used in calls to the Youtube API, and in Googles Firebase Firestore where your custom channel grouping are handled.
                        </p>
                    </p>

                    <p>
                        <h2>What is done with data:</h2>
                        Bubbles uses your Google accounts Youtube id for signing into the Youtube Data API. The Youtube Data API is then used to retrieve the list of channels the authenticated user is subscribed to. It will also be used to fetch a subscribed to channels videos. The users ID will be used in Firebase Firestore to store the users channel groupings.
                    </p>

                    <p>
                        <h2>How user functionality is enhanced through this app:</h2>
                        Users are able to more easily view, and group simalar Youtube subscriptions in a very easy, intuitive way.
                    </p>

                    <p>
                        <h2>Privacy Policy:</h2>

                        <Link to="/bubbles-privacy-policy">
                            Click here for Privacy Policy
                        </Link>

                    </p>
                            
                </div>
            </Box>

        </div>
    </div>
}