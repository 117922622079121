import {  useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { AboutBoxItem, AppBoxItem } from '../Item'
import android_ico from '../android_ico.png';
import ios_ico from '../ios_ico.png';
import react_ico from '../react_ico.png';
import firebase_ico from '../firebase_ico.png';
import bubbles_header from '../bubbles_header.png';
import lhrf_header from '../lhrf_header.png';
import soundboard_header from '../soundboard_header.png';

export default function About() {

    document.title = "Caceres Creations"

    let navigate = useNavigate();

    return <div style={{ 
                display:"flex",
                justifyContent: "center",
                flexDirection: 'column',
                alignItems: "center",
                paddingBottom:"15px",
                marginTop:"1px"}}>

            <div style={{
                  backgroundImage: "linear-gradient(rgba(67,0,255,1) 0%, rgba(89, 61, 255, 1) 100%)",
                  display:"flex",
                  justifyContent: "center",
                  flexDirection: 'column',
                  alignItems: "center",
                  width: "100%",
                }}>
                    <div style={{ color: "#ffffff", margin:"50px" }}>
                        <b style={{fontSize: "32px"}}>
                        Portfolio
                        </b>
                    </div>


                <AppBoxItem title={"Streamer Soundboard"}
                            header={soundboard_header}
                            description={"Includes iconic sounds and phrases of popular streamers ...More sounds will continue to be added!"}
                            videoSrc={"https://www.youtube.com/embed/uKEKWDSEzq8?rel=0&autohide=1&showinfo=0"}
                            appLink={"https://play.google.com/store/apps/details?id=com.cacerescreation.soundboard"} />
                
                <AppBoxItem title={"Local Ham Radio Frequencies"}
                            header={lhrf_header}
                            description={"This app is designed to be a mobile-focused, gps-based way to view RadioReference.com frequency information, for premium accounts."}
                            videoSrc={"https://www.youtube.com/embed/VkSEdd261O0?rel=0&autohide=1&showinfo=0"}
                            appLink={"https://play.google.com/store/apps/details?id=com.cacerescreation.localhamradiofrequencies"} />  
                            
                <AppBoxItem title={"Bubbles Subscription Manager"}
                            header={bubbles_header}
                            description={"View the Youtube channels you're subscribed to in one place, and group channels together"}
                            videoSrc={"https://www.youtube.com/embed/VkSEdd261O0?rel=0&autohide=1&showinfo=0"}
                            appLink={"https://play.google.com/store/apps/details?id=com.cacerescreation.bubbles"} /> 
            </div>

            <div style={{ margin:"50px" }}>
                <b style={{fontSize: "32px"}}>
                Proficiencies
                </b>
            </div>

            <div style={{
                  display:"flex",
                  justifyContent: "center",
                  flexDirection: 'column',
                  alignItems: "center",
                  width: "100%",
                }}>
                <AboutBoxItem ico={android_ico} title={"Android"} description={"Java, Kotlin, Jetpack"}/>
                <AboutBoxItem ico={ios_ico} title={"IOS"} description={"Swift / SwiftUI"}/>
                <AboutBoxItem ico={react_ico} title={"React"} description={"On The Web (This Site), Native"}/>
                <AboutBoxItem ico={firebase_ico} title={"Firebase"} description={"Database, Authentication"}/>
                
            </div>

            <div style={{
                  backgroundImage: "linear-gradient(rgba(67,0,255,1) 0%, rgba(109, 61, 255, 1) 100%)",
                  display:"flex",
                  justifyContent: "center",
                  flexDirection: 'column',
                  alignItems: "center",
                  width: "100%",
                }}>
                <Box
                    sx={[{
                        width: "75%",
                        height: "20vh",
                        margin: "5vh",
                        display: "flex",
                        borderRadius:"10px",
                        justifyContent: "center",
                        flexDirection: 'column',
                        alignItems: "center",
                        backgroundColor: "#1D1D1D",
                        boxShadow: 3
                    }]}>

                    <div style={{ 
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: 'column',
                        alignItems: "center", height:"50%", width:"90%", color: "#ffffff"}}>
                            <br/>
                            <b style={{fontSize: "32px"}}>Contact</b>
                            <br/>
                            <i style={{fontSize: "18px"}}>Get in touch with me at micah@caceres-creations.com</i>
                    </div>
                </Box>
            </div>

        </div>

} // <AboutBoxItem title={""} description={""}/>