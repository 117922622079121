import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import About from './routes/about';
import Apps from './routes/apps';
import Contact from './routes/contact';
import SoundboardPrivacyPolicy from './routes/SoundboardPrivacyPolicy';
import LHRFPrivacyPolicy from './routes/LHRFPrivacyPolicy';
import BubblesPrivacyPolicy from './routes/BubblesPrivacyPolicy';
import BubblesHomePage from './routes/BubblesHomePage';

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: '#5555ff',
      //light: '#ff5555',
    },
    /*info: {
      main: '#ff5555',
      light: '#ff5555',
    },*/
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <BrowserRouter>
        <Routes>
          <Route path='/soundboard-privacy-policy' element={<SoundboardPrivacyPolicy />} />
          <Route path='/local-ham-radio-freqs-privacy-policy' element={<LHRFPrivacyPolicy />} />
          <Route path='/bubbles-privacy-policy' element={<BubblesPrivacyPolicy />} />
          <Route path='/bubbles-home-page' element={<BubblesHomePage />} />

          <Route path="/" element={<App />}>
            <Route path="" element={<About />} />
          </Route>

        </Routes>
      </BrowserRouter>
    </ThemeProvider>,
  document.getElementById('root')
);