import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import cacerescreations_full_icon from '../cacerescreations_full_icon.png';
import tiktok_logo from '../tiktok-logo.png';
import yt_logo_rgb_dark from '../yt_logo_rgb_dark.png';
import '../App.css';

export default function DynamicHeader({dynamic}) {

    const [isShown, setShown] = useState(false);
    const [search, setSearch] = useSearchParams();
    const [hHeight, setHHeight] = useState(75);

        useEffect(() => {
            if(dynamic) {
                const handler = () => {
                setShown((isShown) => {

                    let vh = window.innerHeight * 0.01;
                    var end = 100*vh - 75

                    // set to shrunk
                    if (!isShown && (document.body.scrollTop > end || document.documentElement.scrollTop > end)) {
                        return true;
                    }
            
                    // set to not shrunk
                    if (isShown && document.body.scrollTop < end && document.documentElement.scrollTop < end) {
                        return false;
                    }
            
                    return isShown;
                });
                };
            
                window.addEventListener("scroll", handler);
                return () => window.removeEventListener("scroll", handler);
            }
        }, []);
    
    var pos = dynamic?"fixed":"relative"
    if (dynamic && !isShown)
      return <div />
    else
        return <Box
              id="headerD"
              sx={{
                  zIndex:"2",
                  position: pos,
                  top: "0",
                  width:"100%",
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: 'row',
                  alignItems: "start",
                  background: "#1D1D1D",
                  height:"75px",
                  boxShadow: 3
              }}
          >

        <Link to="/" onClick={()=>{
            window.scrollTo(0, 0)}}>
            <img src={cacerescreations_full_icon} style={{objectFit: "contain", paddingLeft:"5px", paddingTop:"5px", paddingBottom:"5px", height:"60px"}} alt="Logo" />
        </Link>

        <Box
            id="footer"
            sx={{
                width:"100%",
                display: "flex",
                justifyContent: "end",
                flexDirection: 'row',
                alignItems: "center",
                background: "#1D1D1D",
                height:"75px",
            }}>
            <a href="https://www.tiktok.com/@caceres_creations">
                <img src={tiktok_logo} style={{objectFit: "contain", marginTop:"10px", marginLeft:"10px",
                marginBottom:"10px", marginRight:"10px", height:"50px", width:"50px"}} alt="Logo" />
            </a>
            <a href="https://www.youtube.com/channel/UCpvmakEwm7_gE5h3WrtI0pw">
                <img src={yt_logo_rgb_dark} style={{objectFit: "contain", marginTop:"10px", marginLeft:"10px",
                marginBottom:"10px", marginRight:"10px", height:"75px", width:"75px"}} alt="Logo" />
            </a>
        </Box>


    </Box>
}