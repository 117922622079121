import logo from './logo.svg';
import {Grid} from '@mui/material';
import { useState, useRef } from 'react';
import './App.css';
import { useSearchParams, useNavigate, Outlet } from 'react-router-dom';

import DynamicHeader from './routes/DynamicHeader'
import Header from './routes/Header'
import Footer from "./routes/Footer"

function App() {
  
  const titleRef = useRef(null)

  return (
    <div className="App">

      <Header style={{position: "fixed", top: "0px"}} />
      <DynamicHeader dynamic={true} style={{position: "fixed", top: "0px"}} />

      <div ref={titleRef}> <Outlet/> </div>
      
    </div>
  );
}

export default App;